<template>
  <CardTable
    :title="title"
    :subTitle="subTitle"
    :addFilter="addFilter"
    :columns="table.columns"
    :rows="table.rows"
    :isLoading="table.isLoading"
    :pageSize="serverParams.pageSize"
    :pageNumber="serverParams.pageNumber"
    :totalPage="table.totalPage"
    :totalRecords="table.totalRecords"
    @onFilterClick="onFilterClick"
    @onPerPageChange="onPerPageChange"
    @onPageChange="onPageChange"
    @onSearch="onSearch"
  >
    <template #search>
      <div class="ml-3">
        <DatePicker
          :initialDates="[
            serverParams.dateRange.startDate,
            serverParams.dateRange.endDate,
          ]"
          :dateInput="dateInput"
          :switchButtonInitial="true"
          :showHelperButtons="true"
          language="id"
          v-b-tooltip.hover
          title="Tanggal Pendaftaran"
          @date-applied="changeDate"
        />
      </div>
    </template>
    <template #filter>
      <b-row class="pl-2 pt-3 pr-2">
        <b-col xl="3">
          <treeselect
            class="mb-2"
            placeholder="Pilih Store"
            v-model="serverParams.storeId"
            :clearable="false"
            :options="options.store"
            @input="getStoreById"
          />
        </b-col>
        <b-col xl="3" class="pl-0">
          <b-input
            class="mb-2"
            placeholder="Nama Lengkap"
            v-model="serverParams.fullName"
            @input="getAll"
          />
        </b-col>
        <b-col xl="3" class="pl-0">
          <b-input
            class="mb-2"
            placeholder="Nomor Identitas"
            v-model="serverParams.nomorIdentitas"
            @input="getAll"
          />
        </b-col>
        <b-col xl="3" class="pl-0">
          <b-input
            class="mb-2"
            placeholder="Nomor Member"
            v-model="serverParams.membershipNumber"
            @input="getAll"
          />
        </b-col>
      </b-row>
      <b-row class="pl-2 pr-2">
        <b-col xl="3">
          <treeselect
            class="mb-2"
            placeholder="Pilih Jenis Kelamin"
            v-model="serverParams.gender"
            :options="options.gender"
            @input="getAll"
          />
        </b-col>
        <b-col xl="3" class="pl-0">
          <b-input
            class="mb-2"
            placeholder="Email"
            v-model="serverParams.email"
            @input="getAll"
          />
        </b-col>
        <b-col xl="3" class="pl-0">
          <b-input
            class="mb-2"
            placeholder="Nomor WhatsApp"
            v-model="serverParams.phone"
            @input="getAll"
          />
        </b-col>
        <b-col xl="3" class="pl-0">
          <treeselect
            class="mb-2"
            placeholder="Pilih Paket Membership"
            v-model="serverParams.productId"
            :options="options.product"
            @input="getAll"
          />
        </b-col>
      </b-row>
    </template>
    <template #empty>
      <EmptyTable title="Belum ada data member" :description="subTitle" />
    </template>
    <template #cell(image)="data">
      <div v-if="data.value != null" class="symbol symbol-50 symbol-light mr-4">
        <span class="symbol-label">
          <img :src="mediaUrl(data.value?.label)" class="h-75 align-self-end" />
        </span>
      </div>
    </template>
    <template #cell(membership)="data">
      {{ data.item.fullName }}
      <br />
      <small>
        {{ data.item.membershipNumber }}
      </small>
    </template>
    <template #cell(identity)="data">
      {{ data.item.nomorIdentitas }}
      <br />
      <small>
        {{ data.item.identityType.label }}
      </small>
    </template>
    <template #cell(placeOfBirth)="data">
      {{ `${data.value}, ${dateFormat(data.item.dateOfBirth)}` }}
    </template>
    <template #cell(email)="data">
      {{ data.item.phone }}
      <br />
      {{ data.value }}
    </template>

    <template #cell(memberCard.validUntil)="data">
      {{ dateFormat(data.value, "DD MMM YYYY") }}
      <div
        v-if="
          data.item.memberCard?.validUntil != null &&
          data.item.memberCard?.expires
        "
      >
        <b-badge variant="danger"> Expired </b-badge>
      </div>
    </template>
    <template #cell(created.createdAt)="data">
      {{ dateFormat(data.value, "DD-MM-YYYY HH:mm") }}
    </template>
  </CardTable>
</template>

<script>
import { mapGetters } from "vuex";
import { laporanMembership as columns } from "@/core/datasource/column";
import {
  pageSize,
  getStore,
  setRupiah,
  numberFormat,
  normalizer,
  dateFormat,
  dateTimeFormat,
  mediaUrl,
  toDate,
  getDate,
} from "@/core/utils";
import { SET_STORE } from "@/core/services/store/personalize.module.js";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";

export default {
  components: {
    DatePicker,
  },
  data: () => ({
    title: "Membership",
    subTitle: "Menampilkan data membership",
    addFilter: false,
    serverParams: {
      pageNumber: 1,
      pageSize,
      fullName: null,
      dateRange: {
        startDate: toDate(getDate("YYYY/MM/DD 00:00:00")),
        endDate: toDate(getDate("YYYY/MM/DD 23:59:59")),
      },
      nomorIdentitas: null,
      membershipNumber: null,
      gender: null,
      email: null,
      phone: null,
      productId: null,
      storeId: null,
      orderBy: "",
    },
    table: {
      isLoading: false,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
    options: {
      store: [],
      product: [],
      gender: [
        { id: 0, label: "Pria" },
        { id: 1, label: "Wanita" },
      ],
    },
    dateInput: {
      inputClass: "form-control",
    },
  }),
  created() {
    const self = this;

    getStore().then((response) => {
      self.options.store = response.data.map((x) => ({
        id: x.id,
        label: x.storeName,
      }));
      if (self.options.store.length > 0) {
        if (self.store != null) {
          self.serverParams.storeId = self.store.id;
        } else {
          self.serverParams.storeId = self.options.store[0].id;
        }
      }
    });

    self.getProducts();
  },
  computed: {
    ...mapGetters(["store"]),
  },
  methods: {
    numberFormat,
    setRupiah,
    normalizer,
    dateFormat,
    dateTimeFormat,
    mediaUrl,
    changeDate(from, to) {
      const self = this;
      self.serverParams.dateRange.startDate = from;
      self.serverParams.dateRange.endDate = to;
      self.getAll();
    },
    getStoreById(storeId) {
      const self = this;

      if (storeId != null) {
        self.$store
          .dispatch("apis/get", {
            url: `store/${storeId}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.$store.dispatch(SET_STORE, response.data);
            }
          })
          .finally(() => {
            self.getAll();
          });
      }
    },
    getProducts() {
      const self = this;

      if (self.store != null) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/product`,
            params: {
              pageNumber: 1,
              pageSize: 0,
              isMembership: true,
              actived: true,
              orderBy: "",
            },
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.product = response.data.data.map((x) => ({
                id: x.id,
                label: x.product.productName,
              }));
            }
          });
      }
    },
    getAll() {
      const self = this;

      let _serverParams = {
        pageNumber: self.serverParams.pageNumber,
        pageSize: self.serverParams.pageSize,
        startDate: self.serverParams.dateRange.startDate,
        endDate: self.serverParams.dateRange.endDate,
        fullName: self.serverParams.fullName,
        nomorIdentitas: self.serverParams.nomorIdentitas,
        membershipNumber: self.serverParams.membershipNumber,
        gender: self.serverParams.gender,
        email: self.serverParams.email,
        phone: self.serverParams.phone,
        productId: self.serverParams.productId,
      };
      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `/store/${self.serverParams.storeId}/member`,
          params: _serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
    updateParams(newProps) {
      const self = this;
      self.serverParams = Object.assign({}, self.serverParams, newProps);
    },
    onFilterClick(params) {
      this.addFilter = params;
    },
    onPerPageChange(params) {
      const self = this;
      self.updateParams({ pageSize: params, pageNumber: 1 });
      self.getAll();
    },
    onPageChange(params) {
      const self = this;
      self.updateParams({ pageNumber: params });
      self.getAll();
    },
    onSearch(params) {
      const self = this;
      self.updateParams({
        fullName: params,
      });
      self.getAll();
    },
  },
};
</script>
